import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'ZESC',
    MNT: 523571228,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'MONT',
    MNT: 1480230738,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'TRD',
    MNT: 2300999987,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'URG',
    MNT: 2410174638,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'OBOT',
    MNT: 2439324824,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'CHB',
    MNT: 2700015000,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'WPL',
    MNT: 2816717514,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'ARDM',
    MNT: 3404744161,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'ELFC',
    MNT: 4147500000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'CPX',
    MNT: 4900000000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'DAC',
    MNT: 6375000000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'MNFT',
    MNT: 9035827268,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'DC',
    MNT: 9750000000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'SPC',
    MNT: 12321062733,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'CRX',
    MNT: 14637309709,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'ADMC',
    MNT: 34023468229,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'IHC',
    MNT: 46113873683,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'CAEL',
    MNT: 84905000000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'ARDX',
    MNT: 86783526110,
    pv: 4300,
    amt: 2100,
  },
];

function Graphic() {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" domain={['dataMin', 'dataMax']} tick='preserveStart' />
          {/* <YAxis /> */}
          <Tooltip />
          <Legend />
          <Bar dataKey="MNT" fill="#58F1FD" />
        </BarChart>
      </ResponsiveContainer>
  ); 
}

export default Graphic;