import Footer from './components/footer';
import Header from './components/header';
import FiraCard from './components/fira-card';
import InvestmentPic from './assets/investment.png';
import BlockchainPic from './assets/blockchain-removebg-preview.png' 
import Web3Pic from './assets/Web3.png';
import ResearchPic from "./assets/research-removebg-preview.png";
import ClientCard from './components/client-card';
import SeedPic from './assets/Seed_logo_dark.png';
import TapatripPic from "./assets/tapatrip.PNG";
import styles from './App.module.css';
import Graphic from './components/charts';
import DesktopFira from './components/desktop-fira';
import MobileFira from './components/mobile-fira';




function App() {
  return (
    <div>
      <Header/>
      <main>
        <div className={styles['section-1']} >
          <h1>Who we are</h1>
          <p>We are a blockchain investment and development consulting company based in Ulaanbaatar, Mongolia. Our team is composed of experts in blockchain development, finance, and business development. Our goal is to help Mongolian businesses integrate blockchain solutions to their existing business operations & to provide blockchain development training to developers in Mongolia.</p>        
        </div>
        <div className={styles['section-3']}>
          <h1 className={styles['skillset']}>What We Do</h1>
          <div className={styles['utility-1']} >
            <FiraCard className={styles['firacard-1']} title="Cryptocurrency Investment" description="Based on our client’s understanding of blockchain technology and their investment needs, our consultancy service can cover anything from basic exchange operations such as KYC & AML compliance to more advanced investment subjects such as yield farming and liquidity pools." img={InvestmentPic} cardColor="#ffff" marginTop="1rem" />
            <FiraCard title="Blockchain Development" description="For companies wishing to create their own utility token for their existing platforms, developing blockchain based products & solutions, utilizing enterprise blockchain for internal records, operating blockchain nodes etc." img={BlockchainPic} cardColor='black' textColor="#58F1FD" marginTop="4rem" />
            <FiraCard title="Web3 Training" description="Consultancy service for companies and individuals interested in pursuing blockchain based solutions in the future. Our company can conduct training sessions ranging from the basics of blockchain to more complex subjects such as smart contract development." img={Web3Pic} cardColor='#ffff' marginTop="3rem" />
            <FiraCard title="Research" description="For clients interested in pursuing an idea, we can conduct research on the technological & financial implications of turning the idea into a working product." img={ResearchPic} cardColor='black' textColor='#58F1FD' marginTop="9rem" />
          </div>
          {window.innerWidth > 415 ? <DesktopFira/> : <MobileFira/>}
        </div>
        <div className={styles['section-4']}>
          <h1>Our Clients</h1>
          <div className={styles['clients']}>
            <ClientCard img={SeedPic} description="Seed is Mongolia’s first crowdfunding platform that is aiming to offer its users fractionalized NFTs for their pledges. The NFTs can be later traded on any one of Mongolia’s NFT marketplaces." url="https://seed.mn/" width={window.innerWidth > 415 ? "9.5rem" : "4rem"} marginLeft={window.innerWidth > 415 ? "0rem" : "4.5rem"} height={window.innerWidth > 415 ? '10rem' : '4.4rem'}/>
            <ClientCard img={TapatripPic} description="Tapatrip is Mongolia’s first online booking service that covers all modes of transportation within Mongolia. Tapatrip has implemented a unique  Travel-To-Earn blockchain based utility token program instead of mileage points." url="https://tapatrip.com/" marginLeft={window.innerWidth > 415 ? "0rem" : "2.7rem"} width={window.innerWidth > 415 ? "10rem" : "8rem"} height={window.innerWidth > 415 ? "5rem" : "4.4rem"}/>
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
