import styles from "./header.module.css";
import Logo from '../assets/Fira_symbol (1).png';

function Header() {
    return (
        <header className={styles['header-comp']}>
            <div className={styles['img-comp']}>
                <img src={Logo} alt="Logo"/>
            </div>
            <h1 className={styles['title']}>FiraWorks</h1>
        </header>
    );
}

export default Header;