import Logo from '../assets/Fira_symbol (1).png'
import styles from './footer.module.css'

function Footer() {
    return (
            <footer className={styles['foot-comp']}  >
                <div className={styles['foot-up']} >
                    <p>@2022.FiraWorks.</p>
                    <p className={styles['some-text']}>If you wish to contact us, please send your inquiries to contact@firaworks.com</p>
                </div>
            </footer>
    );
}

export default Footer;