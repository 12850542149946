import styles from './fira-card.module.css'


function FiraCard(props) {
    return (
        <div style={{backgroundColor: props.cardColor}} className={styles["card"]}>
            <h1 style={{color: props.textColor}}>{props.title}</h1>
            <div>
                <p style={{color: props.textColor}}>{props.description}</p>
            </div>
            <img src={props.img} alt='pics'/>
        </div>
    );
}

export default FiraCard;