import FiraCard from "./fira-card";
import ProjectPic from '../assets/Project Management.png'
import FinancingPic from '../assets/Finance-removebg-preview.png';
import OutsourcingPic from '../assets/Outsourcing (1).png'
import CompliancePic from '../assets/compliant-removebg-preview.png'
import styles from './mobile-fira.module.css'

function MobileFira () {
    return (
        <div className={styles['utility-2']}>
            <FiraCard title="Project Management" description="Agile project management consulting for startups and web3 development projects. We help teams transition from a traditional waterfall based project management framework to agile kanban." img={ProjectPic} marginTop="6rem" />
            <FiraCard title="Financing & Funding" description="We provide best fit matching services between investors and promising startups in Mongolia. We help startups with improving and fine tuning their pitch as well improving their business structure to better attract investors." img={FinancingPic} marginTop="4rem" textColor="#58F1FD" cardColor="black"/>
            <FiraCard title="Outsourcing" description="Blockchain and web development outsourcing for foreign companies that wish to save on development costs. We connect foreign companies with top developers in Mongolia." img={OutsourcingPic} marginTop="5rem"/>
            <FiraCard title="Compliance" description="Legal and regulatory compliance consulting for companies operating in the blockchain industry in Mongolia. Covers accounting and legal due diligence." img={CompliancePic} marginTop="7rem" textColor="#58F1FD" cardColor="black"/>
        </div>
    )
}

export default MobileFira;