import styles from './client-card.module.css'

function ClientCard(props) {
    return (
        <div className={styles['card']}>
            <div className={styles['content']}>
                  <img src={props.img} alt="logo" style={{ width: props.width, height: props.height, marginLeft: props.marginLeft }} />  
                <div className={styles['text-container']}>
                   <p>{props.description}</p> 
                   <a href={props.url}>{props.url}</a>
                </div>                 
            </div>
        </div>
    );
}

export default ClientCard;